import React, { Component } from "react"
import Title from "./title"
import Text from "./text"
import ProductItem from "./productItem"
import VideoBlock from "./videoBlock"
import styles from "../styles/scss/components/storyBlock.module.scss"
import cn from "classnames"
import Imgix from "react-imgix"
import { supSub } from "../services/util"

export default class StoryBlock extends Component {
    render() {
        const { data, onClickNext, showProducts = true, type = "Live life hands on" } = this.props
        const isLLHO = type === "Live life hands on";
        return (
            <div id="foo" className={isLLHO ? styles.container : styles.containerPro}>
                <div className={isLLHO ? styles.userInfo : styles.userInfoPro}>
                    <div className={styles.titleAndNext}>
                        <Title
                            value={isLLHO ? `${data.name}: Living life hands on` : `${data.name}`}
                            alignLeft
                            className={styles.title}
                        />
                        <a
                            href="javascript:;"
                            className={styles.next}
                            onClick={onClickNext}
                        >
                            {isLLHO ? `View Next Story >>` : `View the next pro >>`}
                        </a>
                    </div>
                    
                    {
                        isLLHO ? <div className={styles.imageVideo}>
                            <Imgix
                                src={`/img/llho/${data.image}`}
                                className={styles.imageUser}
                                alt=""
                                htmlAttributes={{ alt: '' }}
                            />
                            <VideoBlock
                                youtubeVideoId={data.youtube}
                                image={`/img/llho/${data.videoBanner}`}
                                hideBody
                                containerClass={styles.containerImageBanner}
                                imageClass={styles.imageBanner}
                            />
                        </div>
                            :
                            <div className={styles.imageVideo}>
                                <Imgix
                                    src={`${data.image}`}
                                    className={styles.imageUser}
                                    alt=""
                                    htmlAttributes={{ alt: '' }}
                                />
                                {
                                    (data.regularVideo && data.regularVideo !== 'NOVIDEO') &&
                                    <VideoBlock
                                        customVideo={true}
                                        videoUrl={data.regularVideo}
                                        image={`${data.videoBanner}`}
                                        hideBody
                                        containerClass={styles.containerImageBannerPro}
                                        imageClass={styles.imageBanner}
                                    />
                                }

                            </div>
                    }
                    {
                        isLLHO &&

                        <div className={styles.userData}>
                            <Title
                                value={`${data.specialName
                                    ? data.specialName
                                    : data.name.split(" ")[0]
                                    }'s bio`}
                                alignLeft
                                size={25}
                                className={styles.storyTitle}
                            />
                            <Text className={[styles.textInfo, styles.storyText]}>
                                {data.bio}
                            </Text>
                            <Title
                                value="Certifications"
                                alignLeft
                                size={25}
                                className={styles.storyTitle}
                            />
                            <Text className={[styles.textInfo, styles.storyText]}>
                                {data.certifications}
                            </Text>
                            <Title
                                value="What drew you to this Industry?"
                                alignLeft
                                size={25}
                                className={styles.storyTitle}
                            />
                            <Text className={[styles.textInfo, styles.storyText]}>
                                {data.drewindustry}
                            </Text>
                            <Title
                                value="What Pro Tip can you offer viewers about living life hands on in your industry?"
                                alignLeft
                                size={25}
                                className={styles.storyTitle}
                            />
                            <Text className={[styles.textInfo, styles.storyText]}>
                                {data.protip}
                            </Text>
                        </div>
                    }

                    {
                        !isLLHO &&
                        <div className={styles.userDataPro}>


                            {
                                data.adviceYoungerSelf &&
                                <>
                                    <Title
                                        value="Give advice to your younger self - what is one thing you wish you knew earlier in your career?"
                                        alignLeft

                                        className={styles.proTitle}
                                    />
                                    <Text className={[styles.textInfo, styles.storyText]}>
                                        <div dangerouslySetInnerHTML={{ __html: supSub(data.adviceYoungerSelf) }} />
                                    </Text>
                                </>

                            }
                            {
                                data.productRely &&
                                <>
                                    <Title
                                        value="Knowing WD-40 Brand is more than the original blue-and-yellow can, which WD-40 Specialist® product do you rely on most to get the Job Done Right®?"
                                        alignLeft

                                        className={styles.proTitle}
                                    />
                                    <Text className={[styles.textInfo, styles.storyText]}>
                                        <div dangerouslySetInnerHTML={{ __html: supSub(data.productRely) }} />
                                    </Text>
                                </>
                            }

                        </div>
                    }

                </div>
                {
                    showProducts &&
                    <div className={styles.brandProducts}>
                        <Title
                            value={`${data.specialName
                                ? data.specialName
                                : data.name.split(" ")[0]
                                }'s Top 3 WD-40 Brand Products`}
                            alignLeft
                            yellow
                        />
                        <div className={styles.productItems}>
                            <div className={cn(styles.productItemsContainer)}>
                                <div className="row">
                                    {data.products.map(p => {
                                        return (
                                            <ProductItem
                                                className={cn(
                                                    "col-lg-4",
                                                    styles.productItem
                                                )}
                                                key={p.id}
                                                product={p}
                                                imagePadding={5}
                                                noMarginBottom
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        )
    }
}
