import React, { Component } from "react";
import Helmet from "react-helmet";
import Imgix from "react-imgix";
import NewLayout from '../layout/newLayout';
import styles from "../styles/scss/pages/nextLevelPro.module.scss";
import Modal from 'react-responsive-modal';
import Title from '../components/title';
import Text from '../components/text';
import { isArray as _isArray} from "lodash"
import StoryBlock from '../components/storyBlock';
import HeroSimple from '../components/heroSimple';
import CtaBanner from "../components/ctaBanner";
import Input from '../components/input';
import FileUpload from '../components/fileUpload';
import Button from '../components/button';
import { Formik } from "formik";
import { priceSpiderRebind } from "../services/util";
import { supSub } from "../services/util";
import cn from "classnames";
import { nextLevelProCreate } from "../services/nextLevelProCreate"

export default class NextLevelPro extends Component {

    state = {
        modalForm: false,
        modal: false,
        data: undefined,
        customersLimit: 5,
        currentCustomer: 0,
        submitted: false,
        disabledSubmit: false,
        submitting: false,
        modalTitle: "Enter the Next-Level Advice Sweepstakes ",
        fileName: "",
    }

    constructor(props) {
        super(props);
        this.fileRef = React.createRef();
    }

    handleOpenModal(e, i) {
        this.setState({ modal: true, data: e, currentCustomer: i }, () => {
            priceSpiderRebind()
        })
    }

    handleOpenModalForm() {
        this.setState({ modalForm: true, modalTitle: "Enter the Next-Level Advice Sweepstakes ", submitting: false, submitted: false })
    }

    handleNextStoryClick(nextLevelCustomersForNextLink) {
        let newData;
        if (this.state.currentCustomer + 1 >= nextLevelCustomersForNextLink.length) {
            newData = nextLevelCustomersForNextLink.find((e, key) => key === 0)
            this.setState({ data: newData, currentCustomer: 0 }, function () {
                priceSpiderRebind()
            })
        } else {
            newData = nextLevelCustomersForNextLink.find((e, key) => key === this.state.currentCustomer + 1)
            this.setState({ data: newData, currentCustomer: this.state.currentCustomer + 1 }, function () {
                priceSpiderRebind()
            })
        }

    }

    getCustomers(customers, customersLimit) {
        return customers
            .filter((customer, key) => {
                return (customer.selection === "Enter the next-level advice sweepstakes" && key <= customersLimit)
            })
            .map((customer, key) => {
                
                return {
                    "id": customer.id,
                    "name": `${customer.firstName} ${customer.lastName}`,
                    "image": customer.image.url,
                    "title": customer.jobTitle,
                    "youtube": customer.video[0].video && customer.video[0].video.url,
                    "regularVideo": customer.video[0].text && customer.video[0].text,
                    "videoBanner": customer.videoBannerMask && customer.videoBannerMask.url,
                    "bio": customer.bio,
                    "certifications": customer.certifications,
                    "drewindustry": customer.drewindustry,
                    "protip": customer.protip,
                    "adviceYoungerSelf": customer.adviceYoungerSelf,
                    "productRely": customer.productRely,
                }
            })
    }

    handleLoadMoreCustomers(customersLimit) {
        this.setState({ customersLimit: customersLimit + 3 })
    };


    render() {
        const { modalForm, modal, data, customersLimit, disabledSubmit, modalTitle, fileName, submitting } = this.state;
        const { page, customers } = this.props.pageContext
        const { body, subtitle } = page.content[0];
        const heroImage = page.heroImage.url;
        const nextLevelCustomers = this.getCustomers(customers, customersLimit);
        const nextLevelCustomersForNextLink = this.getCustomers(customers, customers.length + 1);


        const RulesMoreInfo = ({ className }) => {
            const clickHere = (<a target="_blank" href="https://s3.us-west-2.amazonaws.com/files.wd40.com/pdf/Sweepstakes+Official+Rules_NextLevelAdviceSweeps%5B8%5D.pdf">click here.</a>)
            return (
                <>
                    <Text className={className}>{`Required Information*`}</Text>
                    <Text className={className}>{`To see full sweepstake rules 
                please `}{clickHere}</Text>
                </>
            )
        };


        return (
            <NewLayout>

                <Helmet>
                    <script src="https://assets.juicer.io/embed-no-jquery.js" type="text/javascript" defer></script>
                    <link rel="stylesheet" href="//assets.juicer.io/embed.css" media="all" type="text/css" />
                </Helmet>

                {page.seo.title && <h1 style={{ display: 'none' }}>{page.seo.title}</h1>}



                {heroImage &&
                    <HeroSimple
                        style={{ 'marginBottom': '10px' }}
                        isnew="true"
                        image={heroImage}
                        mobileimage={heroImage}
                        title={page.seo.title}
                    />
                }

                <div className={styles.body}>
                    <div className={styles.container}>
                        <span className={styles.contentText}>
                            <p>
                                <span className={styles.contentTitle}>{subtitle}</span>
                            </p>
                            <div className={styles.contentBody} dangerouslySetInnerHTML={{ __html: supSub(body) }} />
                            {/* <div
                                className={styles.OpenFormButton}
                                onClick={this.handleOpenModalForm.bind(this)}
                            >
                                <p className={styles.OpenFormText}>{'Enter the next-level advice sweepstakes'}</p>
                            </div> */}

                        </span>
                        <Title
                            className={styles.customersListTitle}
                            value="Meet our Pros"
                            alignLeft
                        />

                        <div className={styles.items}>
                            {nextLevelCustomers.map((e, i) => (
                                <div
                                    key={i}
                                    className={styles.containerImage}
                                    onClick={this.handleOpenModal.bind(this, e, i)}
                                >
                                    <div className={styles.imageUser}>
                                        <Imgix
                                            h={363}
                                            w={363}
                                            src={e.image}
                                            htmlAttributes={{alt: ''}}
                                        />
                                    </div>
                                    <div className={styles.bodyClass}>
                                        <Title className={styles.titleName} >
                                            {e.name}
                                        </Title>
                                    </div>
                                </div>
                            ))}

                        </div>
                        {
                            customersLimit <= nextLevelCustomers.length && (
                                <div
                                    className={styles.LoadMoreButton}
                                    onClick={() => { this.handleLoadMoreCustomers(customersLimit) }}
                                >
                                    <p className={styles.loadMoreText}>{'LOAD MORE'}</p>
                                </div>
                            )
                        }

                    </div>
                </div>
                <div className={styles.separator} />

                <Title
                    value="#NextLevelAdvice"
                    className={styles.livelifeTitle}
                />
                <CtaBanner
                    text="WANT TO GET MORE TIPS AND TRICKS?"
                    buttonText="SUBSCRIBE TO THE NEWSLETTER"
                    buttonUrl="/signup/"
                />
                {
                    modalForm &&
                    <Modal
                        blockScroll={false}
                        open={modalForm}
                        onClose={() => this.setState({ modalForm: false })}
                        classNames={{
                            modal: modalTitle !== 'Thank you' ? styles.customModal : styles.customModalThanks,
                            closeIcon: styles.closeIcon,
                            closeButton: styles.closeButton,
                            overlay: styles.modalOverlay,
                            modalContainer: modalTitle !== 'Thank you' ? styles.modalContainer : styles.modalContainerThanks,
                            root: styles.rootContainer,
                        }}
                        center
                    >
                        <div className={styles.modalFormSection}>
                            <Title className={modalTitle === 'Thank you' ? styles.modalFormTitleThankYou : styles.modalFormTitle} value={modalTitle} />
                            <div
                                className={modalTitle === 'Thank you' ? styles.modalFormBodyThanks : styles.modalFormBody}
                                dangerouslySetInnerHTML={
                                    {
                                        __html: modalTitle === 'Thank you' ?
                                            supSub(`Thank you for your submission to the Next-Level Advice Sweepstakes! Winners will be notified via email on April 17.
                                            \nStay tuned!`) :
                                            supSub('Fill out the form for your chance to win a custom branded Yeti Roadie cooler, a WD-40 hat, two WD-40 Brand shop towels, and the entire WD-40 Specialist line! By entering the Sweepstakes, you will also be added to the WD-40 Email List to hear updates on the Next-Level Advice Sweepstakes and other WD-40 product tips and tricks.')
                                    }}
                            />
                            {
                                modalTitle !== 'Thank you' &&
                                <div className={styles.modalFormWrapper}>
                                    <div className={cn(styles.formItems, "row")}>
                                        <div className={styles.formContainer}>
                                            <Formik
                                                initialValues={{
                                                    first_name: "",
                                                    last_name: "",
                                                    email: "",
                                                    job_title: "",
                                                    advice_younger_self: "",
                                                    product_rely: "",
                                                    video: "",
                                                }}
                                                validate={values => {
                                                    var errors = []
                                                    if (values.first_name === "") {
                                                        errors.push({ field: "first_name", message: "First Name is required" })
                                                    }
                                                    if (values.last_name === "") {
                                                        errors.push({ field: "last_name", message: "Last Name is required" })
                                                    }
                                                    if (values.job_title === "") {
                                                        errors.push({ field: "job_title", message: "Job Title is required" })
                                                    }
                                                    if (values.email === "") {
                                                        errors.push({ field: "email", message: "Email is required" })
                                                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                                                        errors.push({ field: "email", message: "Invalid email address" })
                                                    }

                                                    if (values.advice_younger_self === null || values.advice_younger_self === "") {
                                                        errors.push({ field: "advice_younger_self", message: "Please enter an Advice" })
                                                    }

                                                    if (values.product_rely === null || values.product_rely === "") {
                                                        errors.push({ field: "product_rely", message: "Please enter your favorite WD40 Product" })
                                                    }
                                                    if (errors.length === 0) {
                                                        this.setState({
                                                            disabledSubmit: false
                                                        })
                                                    }
                                                    return errors
                                                }}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    this.setState({
                                                        submitting: true,
                                                    })

                                                    if (disabledSubmit === false) {
                                                        values.video = fileName.name;
                                                        nextLevelProCreate(values).then((result) => {
                                                            this.setState({
                                                                submitted: true,
                                                                submitting: false,
                                                                modalForm: true,
                                                                modalTitle: "Thank you",
                                                            })
                                                            setSubmitting(false)
                                                        }).catch((err) => {
                                                            console.log('ERROR!')
                                                            console.log(err)
                                                            setSubmitting(false)
                                                            this.setState({
                                                                submitted: true,
                                                                modalForm: true,
                                                                modalTitle: "Thank you",
                                                            })
                                                        })
                                                    } else {
                                                        setSubmitting(false)
                                                    }

                                                }}>
                                                {(props) => (
                                                    <form className={styles.form} onSubmit={props.handleSubmit}>
                                                        <div>
                                                            <div className={styles.row}>
                                                                <div style={{ width: '100%' }}>
                                                                    <Input
                                                                        inputTitleClassName={styles.inputTitle}
                                                                        inputContainerClassName={styles.inputContainer}
                                                                        name="first_name"
                                                                        title="First Name*"
                                                                        placeholder=""
                                                                        className={styles.input}
                                                                        onChange={props.handleChange}
                                                                        onBlur={props.handleBlur}
                                                                        value={props.values.first_name}
                                                                    />
                                                                    <div className={cn(styles.feedback, { [`${styles.hidden}`]: (!this.state.disabledSubmit) })}>
                                                                        <ul>
                                                                            {_isArray(props.errors) && props.errors.filter((f) => {
                                                                                console.log('FIELD', f)
                                                                                return props.touched[f.field] && f.field === "first_name"
                                                                            }).map((e) => {
                                                                                return <li key={e.field}>{e.message}</li>
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div style={{ width: '100%' }}>
                                                                    <Input
                                                                        inputTitleClassName={styles.inputTitle}
                                                                        inputContainerClassName={styles.inputContainer}
                                                                        name="last_name"
                                                                        title="Last Name*"
                                                                        placeholder=""
                                                                        className={styles.input}
                                                                        onChange={props.handleChange}
                                                                        onBlur={props.handleBlur}
                                                                        value={props.values.last_name}
                                                                    />
                                                                    <div className={cn(styles.feedback, { [`${styles.hidden}`]: (!this.state.disabledSubmit) })}>
                                                                        <ul>
                                                                            {_isArray(props.errors) && props.errors.filter((f) => {
                                                                                console.log('FIELD', f)
                                                                                return props.touched[f.field] && f.field === "last_name"
                                                                            }).map((e) => {
                                                                                return <li key={e.field}>{e.message}</li>
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className={styles.row}>
                                                                <div style={{ width: '100%' }}>
                                                                    <Input
                                                                        inputTitleClassName={styles.inputTitle}
                                                                        inputContainerClassName={styles.inputContainer}
                                                                        name="email"
                                                                        title="Email Address*"
                                                                        placeholder=""
                                                                        className={styles.input}
                                                                        onChange={props.handleChange}
                                                                        onBlur={props.handleBlur}
                                                                        value={props.values.email}
                                                                    />
                                                                    <div className={cn(styles.feedback, { [`${styles.hidden}`]: (!this.state.disabledSubmit) })}>
                                                                        <ul>
                                                                            {_isArray(props.errors) && props.errors.filter((f) => {
                                                                                console.log('FIELD', f)
                                                                                return props.touched[f.field] && f.field === "email"
                                                                            }).map((e) => {
                                                                                return <li key={e.field}>{e.message}</li>
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div style={{ width: '100%' }}>
                                                                    <Input
                                                                        inputTitleClassName={styles.inputTitle}
                                                                        inputContainerClassName={styles.inputContainer}
                                                                        name="job_title"
                                                                        title="Job Title*"
                                                                        placeholder=""
                                                                        className={styles.input}
                                                                        onChange={props.handleChange}
                                                                        onBlur={props.handleBlur}
                                                                        value={props.values.job_title}
                                                                    />
                                                                    <div className={cn(styles.feedback, { [`${styles.hidden}`]: (!this.state.disabledSubmit) })}>
                                                                        <ul>
                                                                            {_isArray(props.errors) && props.errors.filter((f) => {
                                                                                console.log('FIELD', f)
                                                                                return props.touched[f.field] && f.field === "job_title"
                                                                            }).map((e) => {
                                                                                return <li key={e.field}>{e.message}</li>
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className={styles.row}>
                                                                <div style={{ width: '100%' }}>
                                                                    <Input
                                                                        inputTitleClassName={styles.inputTitle}
                                                                        textarea={true}
                                                                        name="advice_younger_self"
                                                                        title='What advice do you wish you knew when you started out in your industry?*'
                                                                        className={styles.textarea}
                                                                        inputContainerClassName={styles.inputAreaContainer}
                                                                        onChange={props.handleChange}
                                                                        onBlur={props.handleBlur}
                                                                        value={props.values.advice_younger_self} />
                                                                    <div className={cn(styles.feedback, { [`${styles.hidden}`]: (!this.state.disabledSubmit) })}>
                                                                        <ul>
                                                                            {_isArray(props.errors) && props.errors.filter((f) => {
                                                                                console.log('FIELD', f)
                                                                                return props.touched[f.field] && f.field === "advice_younger_self"
                                                                            }).map((e) => {
                                                                                return <li key={e.field}>{e.message}</li>
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className={styles.row}>
                                                                <div style={{ width: '100%' }}>
                                                                    <Input
                                                                        inputTitleClassName={styles.inputTitle}
                                                                        textarea={true}
                                                                        name="product_rely"
                                                                        title='When did you find out WD-40 Brand was more than the original formula, and what’s your favorite product to use on the job?*'
                                                                        className={styles.textarea}
                                                                        inputContainerClassName={styles.inputAreaContainer}
                                                                        onChange={props.handleChange}
                                                                        onBlur={props.handleBlur}
                                                                        value={props.values.product_rely} />
                                                                    <div className={cn(styles.feedback, { [`${styles.hidden}`]: (!this.state.disabledSubmit) })}>
                                                                        <ul>
                                                                            {_isArray(props.errors) && props.errors.filter((f) => {
                                                                                console.log('FIELD', f)
                                                                                return props.touched[f.field] && f.field === "product_rely"
                                                                            }).map((e) => {
                                                                                return <li key={e.field}>{e.message}</li>
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className={styles.row}>
                                                                <FileUpload
                                                                    setDisabledSubmit={(allow) => {
                                                                        this.setState({
                                                                            disabledSubmit: allow
                                                                        })
                                                                    }}
                                                                    setFileName={(filename) => { this.setState({ fileName: filename }) }}
                                                                    title={"Upload your video file (optional)"}
                                                                    name="video"
                                                                    fileRef={this.fileRef} />
                                                                <div className={styles.formSubmission}>
                                                                    {
                                                                        submitting ?
                                                                            <p>{`Your Information is being submitted, please wait`}</p>
                                                                            :
                                                                            <Button
                                                                                text="Submit"
                                                                                newBlue
                                                                                disabled={disabledSubmit}
                                                                                submit
                                                                                className={styles.inputSubmit}
                                                                            />


                                                                    }
                                                                    {
                                                                        (this.state.fileName !== "" && this.state.fileName !== undefined && disabledSubmit) &&

                                                                        <p className={cn(styles.videoSelected, styles.feedback)}>{`Video ${this.state.fileName.name} is selected. 
                                                                        Please click in Upload to continue your submission`}</p>


                                                                    }

                                                                    <RulesMoreInfo className={styles.rulesMoreInfo} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            }


                        </div>
                    </Modal>
                }
                {
                    modal &&
                    <Modal
                        blockScroll={false}
                        open={modal}
                        onClose={() => this.setState({ modal: false })}
                        classNames={{
                            modal: styles.customModal,
                            closeIcon: styles.closeIcon,
                            closeButton: styles.closeButton,
                            overlay: styles.modalOverlay,
                            modalContainer: styles.modalContainer,
                            root: styles.rootContainer,
                        }}
                        center
                    >
                        {data && (
                            <StoryBlock
                                id="bar"
                                data={data}
                                showProducts={false}
                                type="nextLevelPro"
                                onClickNext={this.handleNextStoryClick.bind(this, nextLevelCustomersForNextLink)}
                            />
                        )}
                    </Modal>
                }

            </NewLayout>
        )
    }
}
