import React, { useState } from 'react';
import Imgix from "react-imgix";
import Title from './title';
import styles from '../styles/scss/components/fileUpload.module.scss'
import cn from 'classnames';
import { nextLevelProUpload } from "../services/nextLevelProUpload"
import LoadingOverlay from "react-loading-overlay"


const FileUploader = props => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const uploadFile = (file) => {

        nextLevelProUpload({ fileName: file.name, fileType: file.type })
            .then((result) => {
                fetch(result.data.signedUrl, {
                    method: 'PUT',
                    body: file,
                }).then((res) => {
                    if(res.status === 200){
                        setUploading(false)
                        setUploaded(true)
                        setDisabledSubmit(false)
                    } 
                })
            }).catch((err) => {
                console.log('ERROR!')
                console.log(err)
                setUploading(false)
            })
    }

    const {
        title,
        className,
        style,
        setFileName,
        setDisabledSubmit,
    } = props;
    const hiddenFileInput = React.useRef(null);
    const handleClick = e => {
        hiddenFileInput.current.click();
        setDisabledSubmit(true)
    };

    const ResetUpload = () => {
        return (
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="20px" height="20px" viewBox="0 0 200.000000 194.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,194.000000) scale(0.100000,-0.100000)"
                    fill="#1C3687" stroke="none">
                    <path d="M833 1905 c-188 -34 -362 -125 -497 -261 -253 -252 -339 -626 -222
-966 48 -142 113 -245 226 -358 202 -202 462 -295 750 -269 117 11 196 32 296
79 420 196 633 647 518 1095 -76 292 -312 544 -601 644 -143 48 -325 62 -470
36z m79 -762 l78 -78 78 78 c42 42 82 77 88 77 5 0 36 -27 69 -60 l59 -60 -82
-83 -82 -82 82 -83 83 -82 -63 -62 -62 -63 -83 83 -82 82 -83 -82 -82 -83 -60
60 c-33 33 -60 63 -60 68 0 5 35 45 77 88 l77 80 -79 79 -79 80 59 60 c33 33
62 60 64 60 3 0 41 -35 83 -77z"/>
                </g>
            </svg>
        )
    }

    return (
        <div className={cn(styles.container, className)} style={style}>
            {title && <Title alignLeft value={title} className={styles.title} />}
            <div className={styles.fileInputWrapper}>

                {

                    <LoadingOverlay
                        active={uploading}
                        spinner={
                            <Imgix
                                className={styles.galleryLogo}
                                src="/img/logo.svg"
                                height={100}
                                alt="WD-40 Logo"
                                htmlAttributes={{alt: 'WD-40 Logo'}}
                            />
                        }
                        text="WD-40 is Uploading your video..."
                    >
                        <div>

                            {
                                selectedFile === null &&
                                <div 
                                    aria-hidden
                                    role="button"
                                    className={styles.inputImage} 
                                    onClick={handleClick} 
                                    onKeyDown={handleClick}>
                                    <Imgix
                                        h={96}
                                        w={96}
                                        src={`/img/forms/fileUpload.png`}
                                        htmlAttributes={{alt: ''}}
                                    />

                                    <input type="file"
                                        ref={hiddenFileInput}
                                        onChange={(e) => {
                                            setFileName(e.target.files[0])
                                            setSelectedFile(e.target.files[0])
                                            setDisabledSubmit(true)
                                        }}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            }
                            {
                                selectedFile && 
                                <button
                                    title="Choose a different video"
                                    className={styles.resetUpload}
                                    onKeyDown={(e) => {
                                        setSelectedFile(null)
                                        setDisabledSubmit(false)
                                    }}
                                    onClick={(e) => {
                                        setSelectedFile(null)
                                        setDisabledSubmit(false)
                                    }}
                                >
                                    <ResetUpload />
                                </button>
                            }
                            {
                                uploaded && selectedFile &&
                                <div 
                                    aria-hidden
                                    role="button"
                                    className={styles.inputImage} 
                                    onClick={handleClick}
                                    onKeyDown={handleClick}
                                    >
                                    <Imgix
                                        h={96}
                                        w={96}
                                        src={`/img/forms/fileUpload.png`}
                                        htmlAttributes={{alt: ''}}
                                    />
                                    <p>{`${selectedFile.name} has been uploaded successfully`}</p>
                                </div>
                            }
                            {
                                selectedFile && !uploaded &&
                                <div className={styles.inputImage} >
                                        <div 
                                            aria-hidden
                                            role="button"
                                            className={styles.uploadButton} onClick={(e) => {
                                            e.preventDefault()
                                           
                                            setUploading(true)
                                            uploadFile(selectedFile)
                                        }}>
                                            {`Click to Upload`}
                                        </div>
                                        <p className={styles.selectedFile}>{`${selectedFile.name} has been selected`}</p>
                                </div>
                            }
                        </div>
                        
                    </LoadingOverlay>

                }

            </div>
            <p className={styles.fieldRules}>{`Max upload size is: 10MB in mp4 format`}</p>
        </div>
    );
};

export default FileUploader;
