import axios from "axios"
import { isBrowser } from "./util"

export const nextLevelProUpload = (values) => {
    return new Promise(function(resolve, reject) {
        if(!isBrowser()){
            reject("Do not do this from Node!")
        }else{
            const contactSubmitUrl = "https://82qtggfh53.execute-api.us-west-2.amazonaws.com/default/nextlevelproupload";
            values.env = process.env.GATSBY_DATOCMS_ENV;
            axios.post(
                `${contactSubmitUrl}`,
                values,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Api-Key': 'yMx0gEDCDsaXm81JeMQeN5sBFlfxLOEh63iCboqY',
                    }
                }
            ).then((result) => {
                resolve(result)
            }).catch((err)=>{
                reject(err)
            })
        }
    })
}